.myList {
  max-width: 80vw !important;
  display: flex;
  width: 100%;
  background-color: red;
  margin: auto;
}
.noArticleBox {
  height: 500px;
  border: 3px dashed #a5a5a5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.AddToCarousel {
  width: 400px;
  height: 200px;
  background-color: #f0f2f5;
  border: 3px dashed #a5a5a5;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
  margin: auto;
}

.iconPlus {
  opacity: 0.6;
  font-size: 80px;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}
.AddToCarousel:hover {
  transform: scale(0.9);
}

.AddToCarousel:active {
  transform: scale(1);
  opacity: 0.3;
}

.MyCarousel {
  max-width: 70vw !important;
  margin: auto;
}

.buttonsCustomPadding {
  padding-bottom: 5em;
}
