.fileCardSelectIcon {
  display: block;
  font-size: 28px;
  position: absolute;
  top: 15px;
  left: 15px;
  color: #1890ff;
  /* opacity: 0;
  border: 1px solid red;
  padding: 2px 5px;
  border-radius: 5px;*/
  transition: all 0.2s ease-in-out;
  z-index: 2;
}
.fileCardSelectIcon:hover {
  transform: scale(1.2);
}
.fileCardSelectIcon:active {
  transform: scale(1.5);

  color: rgb(24, 144, 255, 0.6);
}

.fileSelected {
  transform: scale(1);
}
.fileSelected .fileCardFilter {
  opacity: 0;
}
