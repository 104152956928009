.accordion-button:not(.collapsed),
.accordion-button:focus {
  color: #001529 !important;
  background-color: white !important;
  border-color: white !important;
  box-shadow: none !important;
}

.shadow-card {
  box-shadow: 0px 1.3422727584838867px 5.307648181915283px 0px #00000000;
}
.noBorder {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
