.addSection {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px dashed #1890ff;
  color: #1890ff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}
.addSection:hover {
  transform: scale(0.9);
  background-color: rgb(24, 144, 255, 0.1);
}
.addSection:active {
  transform: scale(1);
}
