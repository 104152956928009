.messageStyle h3 {
  color: red;
}

.messageStyle bold {
  color: red;
  font-weight: bold;
}
.info {
  font-weight: bold;
  font-size: 14px;
  margin-top: 15px;
}

.warnIcon {
  font-size: 70px;
}
