.drawerContainer {
  width: 80px;
  background-color: #001529;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  display: flex;
  flex-direction: column;
  position: fixed !important;
  top: 0;
  height: 100vh;
  z-index: 4;
}
.drawerContainer:hover {
  width: 300px;
}
.drawerContainer:hover .logoContainer {
  background-image: url("../../images/taboni_logo.png");
}
.logoContainer {
  height: 220px;
  background-image: url("../../images/Favicon.png");
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.2s ease-in-out;
  background-size: contain;
  margin-bottom: 50px;
  margin-top: 20px;
}
.logoNavigations {
  height: 70%;
}
.drawLink {
  color: white;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  font-size: 16px;
  transition: all 0.2s ease-in-out;
  min-width: 200px;
  transform-origin: left;
}
.drawLink:hover {
  color: white;
  background-color: #1890ff;
  transform: scale(1.1);
}
.inpage {
  background-color: #1890ff;
}

.logout {
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
  cursor: pointer;
  position: relative;
  height: 30%;
  align-items: flex-end;
}
