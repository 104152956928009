.headerContainer {
  height: 70px;
  padding-left: 80px;
}
.languageFlag {
  width: 40px;
  height: 40px;
  background-size: cover;
  border-radius: 0%;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border: 2px solid #ffffff;
  transition: all 0.2s ease-in-out;
}
.searchInput {
  outline: none;
  border: none;
}
.searchInput::placeholder {
  font-weight: 300;
}
.flagfr {
  background-image: url("../../../images/flag_france.png");
}
.flaguk {
  background-image: url("../../../images/flag_uk.png");
}
.usrdefault {
  background-image: url("../../../images/defaultUser.png");
}
.flagSelected {
  border: 2px solid #1890ff;
  transform: scale(1.2);
}
.notifIn {
  position: absolute;
  top: -4px;
  right: -4px;
  font-size: 10px;
  border: 2px SOLID white;
  background-color: red;
  border-radius: 50%;
  height: 13px;
  width: 13px;
}
.bellIcon {
  position: relative;
}
