.headerLoadingContainer {
  background-color: rgb(255, 255, 255);
  height: 70px;

  transition: all 0.2s ease;
  position: relative;
  transform-origin: left;
}
.LoadingBar {
  background-color: rgb(247, 247, 247);

  height: 70px;

  position: relative;
  animation-name: pulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  transition: ease;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.1;
  }

  100% {
    transform: scale(0);
  }
}
