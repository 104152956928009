.fileListContainer {
  gap: 40px;
}
.filesFilter {
  margin-bottom: -5px;
  cursor: pointer;
  padding-bottom: 4px;
  transition: all 0.1s ease-in-out;
  min-width: fit-content;
  max-width: 300px;
}
.filesFilterSelected {
  cursor: pointer;
  border-bottom: #14748e 3px solid;
  color: #051a30;
  font-weight: bold;
  transform: scale(1.1);
}
.fileFilterContainer {
  border-bottom: 2px solid #e8edf3;
  border-collapse: collapse;
  padding-bottom: 4px;
  margin: auto;
  max-width: 80%;
  height: 70px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.fileFilterContainer::-webkit-scrollbar {
  height: 7px;
}

/* Track */
.fileFilterContainer::-webkit-scrollbar-track {
  height: 7px;
  background: #f1f1f1;
}

/* Handle */
.fileFilterContainer::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #201368;
}

/* Handle on hover */
.fileFilterContainer::-webkit-scrollbar-thumb:hover {
  background: #160d61;
}
