.layoutContent {
  min-height: calc(100vh - 70px);
  position: relative;
  left: 80px;
  max-width: calc(100vw - 130px);
}
.layoutRight {
  flex: 1;
}

/************************** LOGIN PAGE *******************************/

.layoutContentLogin {
  min-height: calc(100vh);
}

.layoutContentLogin {
  background-image: url("../../images/loginBG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
