@media screen and (max-width: 1655px) {
    svg.iconConsulte {
        width: 60px !important;
    }
    div.row.hoverConsulteDrawerLitleScreen {
        display: block;
    }
    div.row.hoverConsulteDrawerLitleScreen svg {
        max-height: 47.61px;
    }
    div.row.hoverConsulteDrawerLitleScreen .col div h2 {
        margin-top: 20px;
    }
    div.row.hoverConsulteDrawerLitleScreen .col div h2, div.row.hoverConsulteDrawerLitleScreen .col div p {
        text-align: center !important;
    }
}

