$theme-colors: (
  "primary": #1890ff,
  "dark-blue": #001529,
  "orange": #fd8650,
  "grey": #a5a5a5,
  "lightGrey": #f0f2f5,
  "red": #ff0000,
);

@import "~bootstrap/scss/bootstrap.scss";
