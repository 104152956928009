.myBtn {
  padding: 0% 7%;
  border-radius: 5px;
  font-weight: lighter;
  outline: none;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.myBtn:disabled {
  background-color: #9ca3af1a;
  color: rgb(90, 87, 87);
  border-color: #9ca3af1a;
}
.myBtn:disabled:hover {
  background-color: #9ca3af1a;
  color: rgb(90, 87, 87);
  border-color: #9ca3af1a;
}

.customButtonprimary {
  border: none;
  color: white;
  background-color: #1890ff;
  border: 2px solid #1890ff;
}
.customButtonOutlinedprimary {
  border: 2px solid #1890ff;
  background-color: #fff;
  color: #1890ff;
}
.customButtonOutlinedprimary:hover {
  background-color: #1890ff;
  color: #fff;
}
.customButtonprimary:hover {
  background-color: rgb(255, 255, 255, 0.8);
  border: 2px solid #1890ff;
  color: #1890ff;
}
.iconCustomBtnprimary {
  color: white;
  transition: all 0.2s ease-in-out;
}
.customButtonprimary:hover .iconCustomBtnprimary {
  color: #1890ff;
}
.customButtonOutlinedprimary:hover .iconCustomBtnprimary {
  color: #fff;
}

.customButtonorange {
  border: none;
  color: white;
  background-color: #ff6b00;
  border: 2px solid #ff6b00;
}
.customButtonOutlinedorange {
  border: 2px solid #ff6b00;
  background-color: #fff;
  color: #ff6b00;
}
.customButtonOutlinedorange:hover {
  background-color: #ff6b00;
  color: #fff;
}
.customButtonorange:hover {
  background-color: rgb(255, 255, 255, 0.8);
  border: 2px solid #ff6b00;
  color: #ff6b00;
}
.iconCustomBtnorange {
  color: white;
  transition: all 0.2s ease-in-out;
}
.customButtonorange:hover .iconCustomBtnorange {
  color: #ff6b00;
}
.customButtonOutlinedorange:hover .iconCustomBtnorange {
  color: #fff;
}

.customButtonred {
  border: none;
  color: white;
  background-color: #ff0000;
  border: 2px solid #ff0000;
}
.customButtonOutlinedred {
  border: 2px solid #ff0000;
  background-color: #fff;
  color: #ff0000;
}
.customButtonOutlinedred:hover {
  background-color: #ff0000;
  color: #fff;
}
.customButtonred:hover {
  background-color: rgb(255, 255, 255, 0.8);
  border: 2px solid #ff0000;
  color: #ff0000;
}
.iconCustomBtnred {
  color: white;
  transition: all 0.2s ease-in-out;
}
.customButtonred:hover .iconCustomBtnred {
  color: #ff0000;
}
.customButtonOutlinedred:hover .iconCustomBtnred {
  color: #fff;
}
