.uploaderContainer {
  width: 200px;
  height: 200px;
  max-width: 200px;
  background-color: #f0f2f5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.iconCamera {
  opacity: 0.6;
  font-size: 80px;
}
